

.contacts_section{
  width:100%;
  min-height: 74vh;
}
.contacts_wrapper{
  min-height: 74vh;
	display: flex;
	flex-direction: column;
  align-items: center;
	justify-content: center;
}
.contacts_container{
	  display: flex;
  	flex-wrap: wrap;
  	flex-direction: row;
  	justify-content: center;
  	align-items: flex-start;
  	width: 98vw;
}
.contacts_block{
	  display: flex;
  	position: relative;
  	text-align: center;
  	flex-direction: column;
  	justify-content: flex-start;
  	align-items: center;  
  	padding:1em;
  
    min-height:370px;
  	width: 25%;
  	min-width:400px;
}
.contacts_block img{
  height: 9vh;
}
.contacts_single_frame{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 25vw;

}


.contacts_single_frame img{
  transition:0.2s ease-in-out;
}
.contacts_single_frame:hover img{
  transform: scale(1.1)
}
.contacts_block h4{
  padding-top: 1.5em;
  padding-bottom: 1em;
  margin: 0.5em;
  color:var(--cor_branca);
  font-size: var(--letra_sizeSmall);
  user-select: none;
  /*text-transform: var(--letra_isUppercase);*/
}

.contacts_block a{
  color:var(--cor_branca);
  line-height: 135%;
  text-decoration: none !important;
  margin-top: 10%;
}

.contacts_block h3{
  color:var(--cor_branca);
  font-size: var(--letra_size);
  line-height: 135%;
  text-decoration: none !important;
  margin-top: -10%;
}

.contacts_block p{
  padding: 0;
  font-weight: bold;
  font-size: var(--letra_size);
  color:var(--cor_branca);
  margin: 0;
  line-height: 135%;
}
.contacts_single_frame_nota{
    width:50%;

}
.contacts_single_frame_nota p{
      font-size: var(--letra_sizeSmall)!important;
  }


@media only screen and (max-width: 1400px){
.contacts_container{
  width:90vw;
}
.contacts_block{
  width:70vw;
  padding: 0;
}
.contacts_single_frame{
  width:75vw;
}
.contacts_wrapper{
  padding-top: 6em;
  padding-bottom: 6em;
}
}
@media only screen and (max-width: 1000px){
.contacts_single_frame{
  width:50vw;
}
.contacts_block{
  min-width: 0 !important;
  width:80vw;
}
.contacts_block img{
  width: 5em;
}
}
@media only screen and (max-width: 450px){
.contacts_single_frame{
  width:70vw;
}
.contacts_block{
  min-width: 0 !important;
  width:80vw;
}
.contacts_block img{
  width: 4em;
}
}