.legal-banner {
  background-color: var(--cor_branca);
  color: var(--mv_base);
  font-weight: bold;
  font-size: var(--letra_sizeSmall);
  height: 13vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-weight: 500;
  padding: 0;
  padding-left: 5vw;
  padding-right: 5vw;
}
.legal-banner-mobile {
  display: none;
  width: 0;
  visibility: hidden;

}
.legal-disclaimer{
  width: 100%;
  display: flex;
  align-items: center;
  font-size: var(--letra_sizeSmall);
  color:#aaa;
  justify-content: center;
  font-weight: 500;
  padding-bottom: 2vh;
}
.legal-disclaimer-inner{
  text-align: center;
  width: 50%;
}
a{
  color: var(--mv_base);
}

a:visited{
  color: var(--mv_base);
  font-weight: 500;
}
@media only screen and (max-width: 1000px) {
  .legal-banner {
    display: none;
    width: 0;
    visibility: hidden;
  }
    .legal-disclaimer-inner {
      width: 100%;
    }
  .legal-banner-mobile {
    background-color: var(--cor_branca);
    color: var(--mv_base);
    font-weight: bold;
    gap: 2vh;
    width: 100%;
    font-size: var(--letra_sizeSmall);
    display: flex;
    visibility: initial;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-weight: 500;
    padding: 0;
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
}