.products_heading{
	padding-top: 1em;
	font-family: var(--letra_family);
	font-size: var(--letra_sizeBig);
	margin-bottom: 1em;
	line-height: 135%;
	font-weight: bold;
	text-align: center;
	margin-top: 1.5em;
	/*text-transform: var(--letra_isUppercase);*/
}
.products_container{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: row;
	margin-left: 20vw;
	margin-right: 20vw;
	margin-top:10%;
	margin-bottom: 12.5%;
}
.products_single
{
	position: relative;
	width:25vw;
	aspect-ratio : 1 / 1;
	cursor:pointer;
	padding:0;
	overflow: hidden;
	
}
.products_single_image
{
  z-index:-1;
  position: absolute;
  object-fit: cover;
  position: absolute;
  top: -9999px;
  left: -9999px;
  right: -9999px;
  bottom: -9999px;
  margin: auto;
}

.products_single_overlaycover{
	z-index: 0;
	position: absolute;
	top:0;
	left:0;
	margin: 0;
	padding:0;
	width: 100%;
	cursor:pointer;
	opacity: 0.91;
	height: 100%;
	transition: 0.3s ease-in-out;
	cursor:pointer;
}
.products_single_overlaycover_withoutImg{
	z-index: 0;
	position: absolute;
	top:0;
	left:0;
	margin: 0;
	padding:0;
	width: 100%;
	cursor:default!important;
	opacity: 0.95;
	height: 100%;
}

.products_single:hover .products_single_overlaycover{
	opacity: 0.7;
	cursor:pointer
}
.products_single_text{
	position: absolute;
 	z-index:5;
 	width: 100%;
 	flex-direction: column;
 	height: 100%;
 	display: flex;
 	align-items: center;
 	justify-content: center;
}
.products_single_text_title
{
	/*text-transform: var(--letra_isUppercase);*/
	position: relative;
	text-align: center;
	font-size: var(--letra_sizeBig);
	line-height: 100%;
	width: 80%;
 	color: var(--cor_branca);
 	margin-top: -12.5%;
 	margin-bottom: -12.5%;
}
.products_single_text_seemore
{
	color: var(--cor_branca);
	opacity: 0;
	font-size: 90px;
	transition: 0.25s ease-in-out;
}
.products_single:hover .products_single_text_title{
	opacity: 1!important;
}

/* ~~ Products Slider ~~ */
.productsSplashProperties{
	width: 9vh;
}

.productsSplashProperties img{
	transform: scale(0.5);
	width: 9vh;
	background: transparent;
	border: 0px;
}
.productsSplashProperties img:hover{
	cursor:pointer;
}

.modal{
	margin-top: 13vh;
	height: 70vh;
	width: 60vw;
	background-color: #222;
}
.products_popUp_container{
		background-color: transparent;
  	height: 100%;
  	width: 100%;
}
.products_popUp_container_PopSlideProducts img{
  	background-color: transparent;
  	display: flex;
  	padding: 0;
  	object-fit: contain !important;
  	margin: auto;
  	height: 70vh;
  	width: 60vw;
  	margin: 0.15px;
		justify-content: center;
		align-items: center;
}
.modal > .close {
	margin-top: 13vh;
  cursor: pointer;
  position: fixed;
  display: block;
  padding: 2px 5px;
  line-height: 30px;
  right: 10px;
  top: 10px;
  font-size: 65px;
  background: transparent;
  border-radius: 48px;
  border: 1px solid transparent;
  color: var(--letra_preta);
}
.popupOverlay{
	width:100vw;
	height: 100vh;
	position: fixed;
	top:0;
	left:0;
	background-color: rgba(0,0,0,0.7);
}

/* ~~ IN OTHER DISPLAYS ~~ */
@media only screen and (max-width: 1280px){
	.products_container{
		margin-left:10vw;
		margin-right: 10vw;
	}
	.products_single{
		width:35vw;
	}
	.products_single_text_title{
		font-size:var(--letra_sizeBigTablet);
	}
	.productsSplashProperties{
		width: 9vw;
	}
	.productsSplashProperties img{
		transform: scale(0.5);
		width: 100%;
		background: transparent;
		border: 0px;
	}
	.modal{
		margin-top: 6.5vh;
		height: 100vh;
		width: 100vw;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.products_popUp_container_PopSlideProducts img{
  	background-color: transparent;
  	height: 100vh;
  	width: 100vw;
	}
}
@media only screen and (max-width: 950px){
	.products_single_text_title{
		font-size:calc((var(--letra_sizeBigTablet)) / 1.3);

	}
	.productsSplashProperties{
		width: 9vw;
	}
	.productsSplashProperties img{
		transform: scale(0.5);
		width: 100%;
		background: transparent;
		border: 0px;
	}
	.modal{
		margin-top: 0vh;
		height: 100vh;
		width: 100vw;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.products_popUp_container_PopSlideProducts img{
  	background-color: transparent;
  	height: 100vh;
  	width: 100vw;
	}
}
@media only screen and (max-width: 800px){
	.products_container{
	  margin-left: auto;
		margin-right: auto;
		margin-top:6%;
		margin-bottom: 12.5%;
	}
	.products_heading{
		padding-bottom: 2em;
	}
	.products_single{
	  width:50vw;
	  height:100%;
	}
	.productsSplashProperties{
		width: 9vw;
	}
	.productsSplashProperties img{
		transform: scale(0.5);
		width: 100%;
		background: transparent;
		border: 0px;
	}
	.modal{
		margin-top: 0vh;
		height: 100vh;
		width: 100vw;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.products_popUp_container_PopSlideProducts img{
  	background-color: transparent;
  	height: 100vh;
  	width: 100vw;
	}
}
@media only screen and (max-width: 540px){
	.products_single{
	  width:100vw;
	  padding: 0;
	  height:100%;
	}
	.products_single_text_title{
		line-height: 160%;
	}
	.productsSplashProperties{
		width: 9vw;
	}
	.productsSplashProperties img{
		transform: scale(0.5);
		width: 100%;
		background: transparent;
		border: 0px;
	}
	.modal{
		margin-top: 0vh;
		height: 100vh;
		width: 100vw;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.products_popUp_container_PopSlideProducts img{
  	background-color: transparent;
  	height: 100vh;
  	width: 100vw;
	}
}
