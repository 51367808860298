:root {
  --mv_base: #00acc8;
  --mv_media: #66cdde;
  --mv_leve: #9adee9;
  --letra_preta: #30393a;
  --cor_branca:  #ffffff;
  --cor_cinza: #aaa;


  --letra_size:  1.2em;
  --letra_sizeTablet: 1em;
  --letra_sizeMobile: 1em;

  --letra_sizeBig: calc(var(--letra_size) / 0.56);
  --letra_sizeSmall: calc(var(--letra_size) / 1.43);
  --letra_sizeBigTablet: calc(var(--letra_sizeTablet) / 0.56);
  --letra_sizeSmallTablet: calc(var(--letra_sizeTablet) / 1.43);	
  --letra_sizeBigMobile: calc(var(--letra_sizeMobile) / 0.56);
  --letra_sizeSmallMobile: calc(var(--letra_sizeMobile) / 1.43);

  --letra_family: 'Raleway', sans-serif;
  --letra_isUppercase: uppercase; /*mete isto a "none" para tirares os uppercases dos titulos*/
}
html, body {
    overflow: auto;
}

.site{
	position:absolute;
	left:0;
	right:0;
	top:13vh;
	z-index: -10000000;
	bottom:0;

}


.main-content{
	padding-top: 0vh;

}
.full-width{
	margin:auto;
	padding:0;

}
.section{
	display: flex;
	width:100%;
	padding: 0;
	margin:0;
	min-height: 87vh;
}
.padded_top{
	padding-top: 2.5em;
}
.padded_bottom{
	padding-bottom: 2.5em;
}
.blue_bg{
	background-color: var(--mv_base);
}


h1, h2, h3, h4{
	font-family: var(--letra_family);
	/*font-size:20px;*/
	font-weight: bold;
	font-size: var(--letra_size);
	color:var(--letra_preta);
}
body, p, h5{
	color:var(--letra_preta);
	font-family: var(--letra_family);
	font-weight: 100;
	line-height: 27px;
	font-size: 1.2rem;
}
