.about_textblock{
	margin: auto;
	display: flex;
	box-sizing: border-box;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 1em;
	width:55%!important;
}
.about_textblock h1{
	font-family: var(--letra_family);
	font-size: var(--letra_sizeBig);
	margin-bottom: 1em;
	line-height: 135%;
	text-align: center;
	font-weight: bold;
}
.about_textblock span{
	display:inline-block;
	text-align: center;
	font-family: var(--letra_family);
	font-size: var(--letra_size);
	line-height: 200%;
	font-weight: 300;
	color:  var(--letra_preta);
	font-weight: bold;
}
.about_infoblock
{
	margin:auto;
	padding-top: 5em !important;
	padding-bottom: 5em !important;
 	display:flex;
 	text-align: center;
 	flex-direction: row;
 	justify-content: center;
 	flex-wrap: wrap;

}
.about_infoblock_single
{
	width:20%;
	text-align: center;
}
.about_infoblock_single h4
{
	font-size: var(--letra_size);
	font-family: var(--letra_family);
	font-weight: bold;
}
.about_infoblock_single p
{
	font-size: var(--letra_sizeSmall);
	font-family: var(--letra_family);
	font-weight: bold;
}
.about_infoblock_single_icon
{
	width:100px!important;
	filter: invert(20%) sepia(7%) saturate(769%) hue-rotate(144deg) brightness(97%) contrast(94%);
	pointer-events: none;
	user-select:none;
}
.about_infoblock_single_title
{
	margin-top:0.5em;
	margin-bottom:0.5em;
	font-size: var(--letra_size);
	font-weight: 600;
}
.about_infoblock_single_text
{	
	padding-left:15%;
	padding-right:15%;
	margin-top:0.5em;
	font-size: var(--letra_sizeSmall);
	margin-bottom:0.5em;
	font-weight: 400;
}

@media only screen and (min-width: 1200px) and (max-width:1300px){
.about_infoblock{
	width:100%;
}
.LANDING_PSC_SingleItem{
	width:15%;
	margin-left:1.5em;
	margin-right:1.5em;
}
}
@media only screen and (max-width: 1200px){
	.about_infoblock{
		width:100%;
		gap:3em;
	}
	.about_infoblock_single{
		width:40%;
	}
	.about_textblock span{
		text-indent:0px;
		line-height: 145%;
		width: 100%;
		font-size: var(--letra_sizeTablet);
	}
	.about_textblock h1{
		font-size: var(--letra_sizeBigTablet);
	}
	
}
@media only screen and (max-width: 650px){
	.about_infoblock{
		width:100%;
	}
	.about_infoblock_single{
		width:100%;
	}
	.about_infoblock_single_text{
		width:55vw;
		margin:0.5em;
		padding: 0;
		margin-left:auto;
		margin-right:auto;
	}

	.about_textblock {
		width: 90% !important;
	}
	.about_textblock span{
		text-indent:0px;
		line-height: 145%;
		width: 100%;
		font-size: var(--letra_sizeMobile);
	}
	.about_textblock h1{
		font-size: var(--letra_sizeBigMobile);
	}
}
@media only screen and (max-width: 400px){
	.about_infoblock{
		width:100%;
	}
	.about_infoblock_single{
		width:100%;
	}
	.about_infoblock_single_text{
		width:55%;
		margin:0.5em;
		padding: 0.5em;
		margin-left:auto;
		margin-right:auto;
	}
	.about_textblock {
		width: 90% !important;
	}
	.about_textblock span{
		text-indent:0px;
		line-height: 145%;
		width: 100%;
		font-size: calc(var(--letra_sizeMobile)/1.2);
	}
	.about_textblock h1{
		font-size: var(--letra_sizeBigMobile);
	}
}

