.reviews_block 
{
  	position: relative;
  	height: inherit;
  	display: flex;
  	justify-content: center;
  	align-items: center;
  	width: 100%;
}
.reviews_block_content
{
	width: 85%;
	min-height: 87vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color:var(--mv_base);
}
.reviews_heading
{
	color:white;
	font-size: var(--letra_sizeBig);
	line-height: 100%;
	text-align: center;
	font-weight: bold;
}
.reviews_slideshow
{
	display: block;
	z-index: 500;
	margin-top: 1em;
	margin-left: 15em;
	margin-right: 15em;
	height:40%;
	width:90vw;

}
.review_item_single
{
	max-width:30vw;
	text-align: center;
	padding:1em;
	margin:auto;
	display: flex;
	flex-direction: column;
	justify-content: space-betwen;
	align-items: center;
}
.review_item_single:hover .review_item_single_starContainer
{
	color:#e5ee82;
}
.review_item_single_starContainer
{
	font-size: 35px;
	user-select: none;
	color:#EEDC82;
	transition: 1s ease-in-out;
}
.review_item_single_comment
{
	margin-top:1.0em;
	color:white;
	font-size: var(--letra_size);
	font-weight: 300;
	font-style: italic;
}
.review_item_single_author
{
	color:var(--cor_branca);
	font-size: var(--letra_sizeSmall);
	font-weight: 600;
}
.reviews_slideshow .indicators{
	width:100%!important;
	user-select: none;
	display: flex!important;
	padding:0!important;
	
	flex-direction: row!important;
	justify-content: center!important;
	margin:auto!important;
	margin-bottom:!important;
	font-size:50px;
}
.reviews_slideshow .indicator {
  display: block;
  cursor:  pointer;
  border:  none;
  color:   white;
  opacity: 0.3;
}

.reviews_slideshow .indicator.active {
  color:white;
  opacity: 1;
}


@media only screen and (min-width: 1100px) and (max-width:1300px){
.review_item_single_comment{
	font-size:var(--letra_size)
}
}
@media only screen and (max-width: 1100px){
.review_item_single_comment{
	font-size:var(--letra_size)
}
}
