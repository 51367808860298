
.APP_NavigationComponent{
	margin:0;
	display:flex;
	z-index: 44;
	position: fixed;
	top:0;
	left:0;
	width:100vw;
	/*height: 120px;*/
	height:13vh;
	min-height: 13vh;
	max-height: 13vh;
	color:black;
	justify-content: space-between;
	align-items: center;
	background: var(--mv_base);
	box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
}

.APP_NavigationComponent img{
	margin-left: 3vw;
	cursor: pointer;
}
.burgerHider{
	display: none;
}
.APP_NavigationComponent_Burger{
	z-index: 1000;
	margin:0;
	position: fixed;
	top:0;
	left:0;
	width:100vw;
	/*height: 120px;*/
	height:13vh;
	display: none;
}

.APP_NC_LEFT{
	display: flex;
	flex-direction: row;
	padding-left: 3vw;
}
.APP_NC_LEFT a{
	text-decoration: none !important;
}
.APP_NC_LEFT a:visited{
	color: var(--mv_base);
}
.APP_NC_LEFT img{
	cursor: pointer;
}

.APP_NC_RIGHT{
	display: flex;
	flex-direction: row;
	height: 60px;
	align-items: center;
	width: 50vw;
	justify-content: flex-end;
	padding-right: 3vw;
}
.APP_BC_LEFT{
	display: flex;
	flex-direction: row;
	padding-left: 3vw;
}

.APP_NC_L_logobox{
	display: flex;
	text-decoration: none;
	flex-direction: row;
	align-items: center;
	margin-top: 2vh;
}
.APP_NC_L_L_logoimg{
	height: 9vh;
}
.APP_NC_L_L_logoimgBurger{
	height: 9vh;
	padding: 0!important;
}
.APP_NC_R_menuItem{

	/*text-transform: uppercase;*/
	text-decoration: none;
	font-family: var(--letra_family);
	color:var(--cor_branca);
	padding-left: 2.4em;
}
.APP_NC_R_menuItem a{
	font-size: var(--letra_size);
	text-decoration: none;
	color:var(--cor_branca);
	position: relative;
	font-weight: bold;
	user-select: none;
	cursor: pointer;
}

.APP_NC_R_menuItem a:hover{
	transition: 0.3s ease;
}

.APP_NC_R_menuItem a::before{
	content:"";
	position: absolute;;
	display: block;
	width: 100%;
	height:3px;
	bottom: -6px;
	left:0;
	background-color: var(--cor_branca);
	transform:scaleX(0);
	transition: transform 0.3s ease;
}
{/*
.APP_NC_R_menuItem a:hover::before {
  transform: scaleX(1);
}
.APP_NC_R_menuItem a.ACTIVEPAGE::before {
  transform: scaleX(1);
}
*/}

.APP_NavigationFiller{
	margin:0;
	display:none;
	z-index: 1000;
	position: fixed;
	top:0;
	left:0;
	width:100vw;
	overflow: none;
	/*height: 120px;*/
	height:13vh;
	min-height: 13vh;
	max-height: 13vh;
	color:black;
	justify-content: space-between;
	align-items: center;
	background: var(--mv_base);
}

.APP_HamburgerMenu{
	display: none;
	z-index: 1000;
	margin:0;
	position: fixed;
	left:0;
	width:100vw;
	/*height: 120px;*/
	height:100vh;
	top: 0px !important;
}

.burgerbutton{
	width:55px;
	height:5vh;
	z-index: 2000;
	top:4vh;
	right:3vh!important;
}
.bm-burger-bars{
	z-index: -111!important;
	background-color: white;
	border-radius: 10px;
}
.bm-burger-button{
	z-index: 1011!important;
	position: fixed;
}
.bm-overlay{
	width:100vw;
}
.bm-menu{
	background: var(--mv_base);
	overflow-y:none;
	height: 100vh;
}
.APP_BC_TOP{
	display: flex;
	align-items: center;
	width:75%;
	height:13vh;
	justify-content: right;

}
.APP_BC_BOTTOM{

	display: flex;
	padding: 0;
	margin: 0;
	width:100%;
	gap:5vh;
	align-items: right;
	flex-direction: column;
}
.APP_BC_menuItem{
	text-decoration: none;
	font-family: var(--letra_sizeBigTablet);
	color:var(--cor_branca);
	padding:0 !important;
	margin-left: 6vh;
	margin-top:3.5vh;
	margin-bottom: 2.5vh;
	width:90vw !important;
	height:6vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0,0,0,0.0)
}
.X{
	text-decoration: none;
	font-family: var(--letra_family);
	font-weight: 600;
	font-size: var(--letra_sizeBigTablet);
	color:var(--cor_branca);
}

.APP_BC_menuItem a{
	font-size: var(--letra_sizeBigTablet);
	text-decoration: none;
	color:var(--cor_branca);
	position: relative;
	font-weight: bold;
	user-select: none;
	cursor: pointer;
}

.APP_BC_logobox{
	display: flex;
	text-decoration: none;
	height: 13vh;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.bm-item-list{
	padding-top: 0px !important;
}
.bm-item {
  display: flex;
  align-items: center;
  padding: 2.5vh;
  padding-left: 10vw;
  justify-content: center;
}
.bm-cross-button{
	height: 5vh!important;
  width: 5vh!important;
	z-index: 1000;
	top: 3vh !important;
	right: 5vh!important;
}
.bm-cross {
  background: #bdc3c7;
  width: 1vh !important;
  height:7vh !important;
  border-radius: 5px;
  color: white;
  background-color: white;
}
.bm-cross-button span{
	top:-10px;
	right:7px;
}
.APP_BC_logobox img{
	width: 7vh;
}
.bm-cross-button:hover .bm-cross{
	background-color: rgb(153,222,233);
	transition:0.1s ease-in-out;
}
.bm-item-list{
	padding-top:1vh;
	width: 100vw !important;
}
.bm-item{
	padding-left: 10vw;
}

/*
.APP_FooterComponent{
	min-height:350px;
	padding-top: 8em;
	background-image: url('../assets/splash/splash_generic1.jpg');
	width:100%;
	margin-bottom: 0;
	padding-bottom: 0;
}
.APP_FC_Container{
	display: flex;
	flex-wrap: wrap;
	padding-top: 5em;
	padding-bottom: 5em;
	padding-right: 15em;
	padding-left: 15em;
	flex-direction: row;
	justify-content: center;
	gap:5em;
}
.APP_FC_C_logoimg{
	width:200px;
	line-height: 100%;
}
.APP_FC_C_Box{
	width:20%;
	line-height: 110%;
}
.APP_FC_C_B_GrayText{
	color:#969696!important;
}
.APP_FC_C_Box h4{
	opacity:1;
	font-weight: 600;
	color:white!important;
}
.APP_FC_C_Box span{
	opacity:1;
	color:white!important;
}
.APP_FC_C_Box span, span:visited, span:hover, span:active {
  color: inherit;
}
*/
.burgerModal{
	display: none;
	position: fixed;
	z-index: 700;
	top:0;
	left:0;
	width:100vw;
	height:100vh;
	overflow: none;
	background-color: rgba(0,0,0,0.7);
}
.APP_FooterExtension{
	width: 100%;
	display: flex;
	height:75px;
	background-color: #2d2d2d;
	justify-content: center;
	align-items: center;
	padding: 0;
	color:#969696!important;
	font-size:0.8em!important;
}

@media only screen and (min-width: 1100px){
.APP_FC_C_Box{
width: 20%;
}
}
@media only screen and (max-width: 1100px){
.APP_FC_Container{
	padding-left: 5em;
	padding-right: 5em;
}
.APP_FC_C_Box{
width: 40%;
}
}

@media only screen and (max-width: 950px){
	.burgerHider{
		display: initial;
	}
.APP_NavigationComponent{
	display: none;
}
.APP_NavigationFiller{
	display: flex;
	box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
}
.APP_NavigationComponent_Burger{
	display: initial;
}
.APP_HamburgerMenu{
	display: initial;
}
}



@media only screen and (max-width: 650px){
.APP_FC_Container{
	padding-left: 2em;
	padding-right: 2em;
}
.APP_FC_C_Box{
width: 80%;
}
}
