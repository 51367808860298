.landing_content{
	width:100%;
  	padding:0;
  	display:block;
}
.landing_content img{
	height:calc(87vh);
	object-fit: cover;
	
}
.landing_slide{
	position: relative;
}
.landing_slide_single{
	z-index: 100;
	position: absolute;
	display: flex;
	width:100%;
	height:100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.landing_slide_single_title{
	font-size:7rem;
	min-width:50vw;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0.3em;
	padding:0;
	line-height: 100%;
	user-select: none;
	color:#fff; 
  	animation: fade-in 2s ease-in-out;
}
@keyframes fade-in {
  0%{
  	opacity: 0;
  	filter: blur(10px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
.landing_slide_single_text{
	font-size:var(--letra_sizeBig);
	max-width:75vw;
	margin-left: auto;
	margin-right: auto;
	margin-top:0;
	line-height: 130%;
	font-weight: 500;
	user-select: none;
	color:#fff;
	animation: fade-in 2s ease-in-out;
}
.landing_slide_single_button{
	height:50px;
	display: flex;
	justify-content: center;
	align-items: center;
	color:white;
	cursor:pointer;
	text-transform: uppercase; 
	font-family: 'Titillium Web', sans-serif;
	font-weight: 700;
	text-align: center;
	margin-top: 2.5em;
	padding: 5px 35px 5px 35px;
	background-color: #00acc8;
	animation: fade-in 2s ease-in-out;
}
.landing_slide_single_button:hover{
	color:#1d535f;
	background-color: #ddd;
	transition: 0.3s ease-in-out;
}

.landing_slide_single_imgButton{
	filter:brightness(100%)!important;
	height:100px !important;
	transition: 0.3s ease-in-out;
	cursor: pointer;
}
.landing_slide_single_imgButton:hover{
	height:120px !important;
}

@media only screen and (min-width: 1100px) and (max-width:1300px){
}
@media only screen and (max-width: 1100px){
.landing_slide_single_title{
	font-size:70px;
}
.landing_slide_single_text{
	font-size:var(--letra_sizeBigTablet);
}
.landing_slide_single_button{
	height:50px;
}
}
@media only screen and (max-width: 650px){
.landing_slide_single_title{
	font-size:60px;

}
.landing_slide_single_text{
	font-size:var(--letra_sizeBigMobile);
}
.landing_slide_single_button{
	height:50px;
}
}
@media only screen and (max-width: 400px){
.landing_slide_single_title{
	font-size:50px;

}
.landing_slide_single_text{
	font-size:var(--letra_sizeBigMobile);
}
.LANDING_BSC_TC_Button{
	height:40px;
}
}